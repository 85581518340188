import { ModalBase } from "../modal_base";
import { AjaxSync } from '../../shared/ajax_utilities'
import { TachoMissingMileage_load_information_into_modal } from '../../form/tacho_vehicle_activity'
import { DateTime } from 'luxon'

export class TcoMisMilCommentEditVehicleActivity extends ModalBase {
    constructor() {
        super('tco_mis_mil_comment_edit');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        window.dispatchEvent(new Event('RebuildAutocompletes'));
        TachoMissingMileage_edit_comment($modalTriggerBtn, this.MODAL_CONTAINER_EL);
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const noteText = this.MODAL_CONTAINER_EL.find('#comment_edit').val();
            if(!noteText || noteText.trim().length === 0) {
                this.MODAL_CONTAINER_EL.find('#comment_edit').addClass('error');
                return;
            }

            this.MODAL_CONTAINER_EL.find('#comment_edit').removeClass('error');
            const el = $(e.currentTarget);
            let data = additionalData;
            if(el.data('btn-action')) {
                data.modalClickedAction = el.data('btn-action');
            }

            const event = new CustomEvent('ModalConfirm', {
                detail: data
            });
            event.modalId = this.MODAL_ID;
            event.modalTriggerButton = $modalTriggerBtn;
            window.dispatchEvent(event);
        });
    }
}

function TachoMissingMileage_edit_comment($modalTriggerBtn, $modal)
{
    const rowID = $modalTriggerBtn.parents('tr').attr('id').substring(1);
    $modal.find('[name="gaps"]').val(rowID);
    AjaxSync(
      {
          url: `${app.CACHE.URL_ADMIN}ajax/missing_mileage/${rowID}`,
          method: 'GET'
      },
      { done: [
        TachoMissingMileage_load_information_into_modal($modal),
        TachoMissingMileage_prefill_modal($modal),
      ]}
    )
}

function TachoMissingMileage_prefill_modal ($modal) {
    return function (data) {
        const row = data.data;
        $modal.find('#comment_id').val(row.comment_id)
        $modal.find('#comment_edit').val(row.comment)

        $modal.find('#tco_user_name').val(row.comment_driver_name);
        $modal.find('#tco_user_id').val(row.comment_driver_id);

        if(row.comment_author_name) {
            $modal.find('#comment-author').html(`<p><b>Comment Author:</b> ${row.comment_author_name}</p>`);
        }

        if(row.comment_date) {
            const commentDate = DateTime.fromISO(row.comment_date)
            $modal.find('#comment-timestamp').html(`<p><b>Comment Date:</b> ${commentDate.toLocaleString(DateTime.DATETIME_MED)}</p>`);
        }
    }
}