import { TableBase } from '../../tables/table_base'
import { DateTime } from 'luxon'
import { LUXON_FORMATS } from '../../../shared/common'

class ExternalIntegrations extends TableBase
{
  constructor () {
    super('external_integrations')
  }

  paintTable($table, data)
  {
    $table.empty()
    let hasRows = false;
    for(const r of data) {

      const $row = $('<tr>');
      const $oprCell = $('<td>')
      const $operator = $('<a>')
        .attr({
          'href': `${app.CACHE.URL_ADMIN}opr/${r.operator_id}`,
          'data-tbl': 'opr',
          'data-id': r.operator_id
        })
        .addClass('hover-preview')
        .text(r.operator_name);
      $oprCell.append($operator);

      const integrationLabel = '';
      const last_fetch_time = r.last_fetch_time !== 'Unknown' ? DateTime.fromISO(r.last_fetch_time).toLocaleString(LUXON_FORMATS.GENERAL_DATETIME_FORMAT) : 'Unknown'
      const last_driver_file = r.last_driver_file !== 'Unknown' ? DateTime.fromISO(r.last_driver_file).toLocaleString(LUXON_FORMATS.GENERAL_DATETIME_FORMAT) : 'Unknown'
      const last_vehicle_file = r.last_vehicle_file !== 'Unknown' ? DateTime.fromISO(r.last_vehicle_file).toLocaleString(LUXON_FORMATS.GENERAL_DATETIME_FORMAT) : 'Unknown'
      const $integrationCell = $('<td>').text(integrationLabel)
      const $queuedCell = $('<td>').text(last_fetch_time)
      const $driverCell = $('<td>').text(last_driver_file)
      const $vehicleCell = $('<td>').text(last_vehicle_file)

      $row.append($oprCell, $integrationCell, $queuedCell, $driverCell, $vehicleCell);
      $table.append($row);
      hasRows = true;
    }

    if( !hasRows ) {
      $table.html('<tr><td colspan="4">No External Integrations Configured</td></tr>');
    }
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new ExternalIntegrations();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});