import {ModalBase} from "../modal_base";
import 'jquery-ui';
import { AjaxSync } from '../../shared/ajax_utilities'
import { TachoMissingMileage_load_information_into_modal } from '../../form/tacho_vehicle_activity'

export class TcoMisMilCommentAddVehicleActivity extends ModalBase {
    constructor() {
        super('tco_mis_mil_comment_add');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        window.dispatchEvent(new Event('RebuildAutocompletes'));
        TachoMissingMileage_add_comment($modalTriggerBtn, this.MODAL_CONTAINER_EL);
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const noteText = this.MODAL_CONTAINER_EL.find('#comment').val();
            if(!noteText || noteText.trim().length === 0) {
                this.MODAL_CONTAINER_EL.find('#comment').addClass('error');
                return;
            }

            this.MODAL_CONTAINER_EL.find('#comment').removeClass('error');
            const el = $(e.currentTarget);
            let data = additionalData;
            if(el.data('btn-action')) {
                data.modalClickedAction = el.data('btn-action');
            }

            const event = new CustomEvent('ModalConfirm', {
                detail: data
            });
            event.modalId = this.MODAL_ID;
            event.modalTriggerButton = $modalTriggerBtn;
            window.dispatchEvent(event);
        });
    }
}

export function TachoMissingMileage_add_comment($btn, $modal) {
    const rowID = $btn.parents('tr').attr('id').substring(1);
    $modal.find('[name="gaps"]').val(rowID);
    AjaxSync(
      {
          url: `${app.CACHE.URL_ADMIN}ajax/missing_mileage/${rowID}`,
          method: 'GET'
      },
      { done: TachoMissingMileage_load_information_into_modal($modal)}
    )
}

