$(() => {

    function setupForm()
    {
        const $form = app.DOM.content.find('#form-opr-ear');
        const $selectPeriods = $form.find('#opr_ear_dropdown');
        const $selectDepot = $form.find('#operator_depot_id');
        const $filterM = $form.find('input.inline');
        
        // change period
        $selectPeriods.on('change', function(){
           
            const  url = `${app.CACHE.URL_ADMIN}er_maintenance/`;
            const val = this.value.split('_');
            
            // redirect
            window.location = url + val[0] + '/' + val[1];
        });
        
        // change depot
        $selectDepot.on('change', function(){
           
            const operator_depot_id = ( this.value === '' ) ? '0' : this.value;

            const ajax = $.post(`${app.CACHE.URL_AJAX}dashboard_filter`, {
                operator_depot_id: operator_depot_id
            });

            ajax.done(() =>{
                window.location.reload();
            });
        });
        
        $filterM.on('change', function(){
           $form.submit(); 
        });
    }
   
    setupForm();
});