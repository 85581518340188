import CalendarTab from './_calendar_tab'
import { DateTime } from 'luxon'
import {AjaxSync} from "../shared/ajax_utilities";

export default class VehicleCalendarTab extends CalendarTab
{
    #vehicleId;
    constructor($tab) {
        super($tab);

        this.#vehicleId = $('#vehicle_id').val();
        this.setupContextMenus($tab.find('#tacho_calendar'));
    }

    rebuild_calendar(baseDate) {
        const $table = this._$hostingTab.find('table#tacho_calendar');
        let sourceURL = $table.data('render-url') + '/' + this.combineParams({
            'date': baseDate,
            'vehicle_id': this.#vehicleId,
        });

        $table.addClass('loading');
        AjaxSync({ url: sourceURL, method: 'GET' }, {
            done: (content) => {
                $('table#tacho_calendar').html(content).addClass('loading');
                this._$hostingTab.find('#tacho_calendar.loading .tacho_calendar_controls').append(this.spinner);

                this.connectHandlers();
                this.fetchData()
            }
        });
    }

    fetchData()
    {
        const $table = this._$hostingTab.find('table#tacho_calendar');
        let sourceURL = $table.data('source-url');
        if (sourceURL === null) {
            return;
        }
        let baseDate = DateTime.fromISO(this._$hostingTab.find('input#base_date').val());
        sourceURL = sourceURL + '/' + this.combineParams({
            'month': baseDate.toFormat('yyyy-MM'),
            'vehicle_id': this.#vehicleId,
        });

        $table.addClass('loading');
        AjaxSync({
            url: sourceURL,
            method: 'GET',
            dataType: 'json'
        }, {
            done: (content) => {
                this.renderCalendar($table, content);
                this.calenderRendered($table);
            }
        });
    }

    renderCalendar($table, content) {
        const numberFormat = Intl.NumberFormat('en-GB', { style: 'decimal'});
        for(const f in content.data) {
            const a = content.data[f];

            const $cell = $table.find(`td#${f}`);

            if (f.includes('W')) {

                // WEEKLY summary
                for(const t of ['Driving', 'Duty', 'Mileage']) {
                    const totalKey = 'Total' + t;
                    const averageKey = 'Average' + t;
                    let html = '';

                    if (a[totalKey] !== undefined && a[averageKey] !== undefined) {
                        html = `<span>${a[totalKey]} (${a[averageKey]})</span>`;
                    }

                    $cell.find(`li.${t}`).html(html);
                }

                if ( a.SpeedEvents ) {
                    $cell.find(`li.SpeedEvents`).html(`<span>${a.SpeedEvents}</span>`).attr('data', a.SpeedEvents);
                } else {
                    $cell.find(`li.SpeedEvents`).hide();
                }
            } else {

                for(const t of ['Driving', 'Duty', 'SpeedEvents', 'Mileage', 'DailyCheck', 'MissingMileage']) {

                    let $item = $cell.find(`li.${t}, span.icon.${t}`);

                    // update text

                    if( t === 'DailyCheck' ) {
                        $item = this.renderCalendarIcons($item, a[t], t);
                    } else if (t === 'MissingMileage' && a[t]) {
                        this.renderMissingMileage($item, a[t])
                    } else {

                        if( t === 'SpeedEvents' && a.SpeedEvents ) {
                            $item.attr('data', a.SpeedEvents);
                        }

                        if (t === 'Mileage' && a[t])
                        {
                            let html = `<span>${numberFormat.format(a[t])}`
                            if (a.Odometer) {
                                html += ` (${numberFormat.format(a.Odometer)})`
                            }
                            html += '</span>';
                            $item.html(html);
                        }
                        else if( a[t] ) {
                            $item.html(`<span>${a[t]}</span>`);
                        } else {
                            $item.hide();
                        }
                    }
                }
            }

            $cell.off('click').not('.week_summary').on('click', this.displayVehicleActivityTab.bind(this));

            $cell.find('li.SpeedEvents').off('click').filter('[data]').on('click', this.activateTab('overspeed'));

            $cell.addClass('has-data');
        }
    }

    triggerRemoteRebuildTables(tabName)
    {
        if (!tabName) {
            tabName = this._$hostingTab[0].className.split(' ')[1].substring(4);
        }
        tabName = (tabName.substring(0, 1).toUpperCase() + tabName.substring(1))
            .replace(/-([a-z])/, (m, p1) => p1.toUpperCase());

        super.triggerRemoteRebuildTables(`Vehicle${tabName}Tab`)
    }

    renderMissingMileage ($item, aElement) {
        const mileage = aElement.mileage;
        const direction = aElement.start ? 'left' : 'right';
        const date = DateTime.fromISO(aElement.start || aElement.end)

        const label = `<span class="icon" title="${mileage} km to ${date.toLocaleString()} missing"><i class="fa-solid fa-arrow-${direction}-from-bracket"></i></span>`
        let $icon = $(label);

        $item.append($icon)
    }
}