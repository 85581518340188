import {BaseGraph} from "./base_graph";
import {AjaxPromise} from "../shared/ajax_utilities";

class ResellerFinance extends BaseGraph {
    constructor() {
        super('res_finance');
    }

    setup() {
        super.setup();

        // setup filter resellers
        if( app.CACHE.USER.user_type_id === '1' ){
            this.setupResellerFilter();
        }
    }

    postSetup($parent, data) {
        $parent.find('#res_finance_selected').text(data.selected);
    }

    getChartJsOptions(options, data) {
        // x axis £
        options.scales = { x: { ticks: { callback: r => { return app.DASHBOARD_CORE.num(r, true); } } } };
        
        return options;
    }

    setupResellerFilter() {
        var $btnOpen = app.DOM.content.find('#filter-res-btn'),
            $content = app.DOM.content.find('#res_finance'),
            $input = $content.find(':input'),
            $btnAll = $content.find('#filter-res-all');
        const allFilters = $content.find('#filter-res input[type="checkbox"]');

        // show resellers
        $btnOpen.on('click', () => {
            // change box state
            $content.toggleClass('filter-res');
            this.checkAllFiltersChecked(allFilters, $btnAll);
        });

        $btnAll.on('click', async (e) => {
            const allChecked = this.checkAllFiltersChecked(allFilters, $btnAll);
            if(!allChecked) {
                // change input state
                $input.prop('checked', true);
                $btnAll.text('Un-Check All');
                this.updateGraphFilters($input);
                return;
            }

            $input.prop('checked', false);
            $btnAll.text('Check All');
        });

        allFilters.on('change', async (e) => {
            this.checkAllFiltersChecked(allFilters, $btnAll);
            this.updateGraphFilters($input);
        });

        app.DOM.content.find('.ref_finance_filter').on('click', (e) => {
            e.preventDefault();
            const el = $(e.currentTarget);
            app.DASHBOARD_CORE.rebuildGraph(this.GRIDSTACK_NAME, {
                res_finance_change: 'set',
                res_finance_value: el.attr('data'),
            }, el).then(() => {
                app.DOM.content.find('.ref_finance_filter').removeClass('button-primary');
                el.addClass('button-primary');
            });
        });
    }

    checkAllFiltersChecked(allFilters, $btnAll) {
        let allChecked = true;
        $.each(allFilters, (k, el) => {
            if(!$(el).is(':checked')) {
                allChecked = false;
            }
        });

        if(allChecked) {
            $btnAll.text('Un-Check All');
            return true;
        }

        $btnAll.text('Check All');
        return false;
    }

    async updateGraphFilters($input) {
        var unchecked = [];

        // loop through and check which ones are unchecked
        $input.each(function(){
            if( $(this).is(':checked') === false ) {
                unchecked.push(this.value);
            }
        });

        const inputs = app.DOM.content.find('.grid-stack').find('input, select, a, button');
        inputs.attr('disabled', 'true').addClass('disabled');
        try {
            const res = await AjaxPromise({
                method: 'POST',
                url: app.CACHE.URL_AJAX + 'dashboard_res_exclude',
                data: {
                    unchecked: unchecked
                }
            });

            if(res.status !== 'success') {
                window.location.reload();
                return;
            }
            await app.DASHBOARD_CORE.rebuildGraph(this.GRIDSTACK_NAME);
        } finally {
            inputs.removeAttr('disabled').removeClass('disabled');
        }
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new ResellerFinance();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});
