import {AjaxSync} from "../shared/ajax_utilities";
import {modalGeneric} from "../shared/common";
import { buildPipedUrlParametersFromNameValuePairs } from '../shared/report-parameters'
import { DateTime } from 'luxon'

let $selectAllCheckbox;
let $checkbox;

function TachoMissingMileage_init() {
    $selectAllCheckbox = app.DOM.content.find('input#checkall');
    $checkbox = app.DOM.table.find('td.col_multi input');

    function checkAddDeleteButtons()
    {
        let canAdd = false;
        let canDelete = false;
        let checked = $checkbox.length;

        if (checked) {
            $.each($checkbox, (k, el) => {
                if( $(el).is(':checked') ) {
                    if ( $(el).closest('tr').hasClass('has-comment')) {
                        canDelete = true;
                    } else {
                        canAdd = true;
                    }
                }
            });
        }

        if ( checked && canAdd ) {
            app.DOM.btn_remodal_bulk_add.addClass('button-green').removeClass('button-grey');
        } else {
            app.DOM.btn_remodal_bulk_add.removeClass('button-green').addClass('button-grey');
        }

        if ( checked && canDelete ) {
            app.DOM.btn_remodal_bulk_delete.addClass('button-red').removeClass('button-grey');
        } else {
            app.DOM.btn_remodal_bulk_delete.removeClass('button-red').addClass('button-grey');
        }
    }

    $checkbox.on('click', checkAddDeleteButtons);

    // any checkbox click
    $selectAllCheckbox.on('click', (e) => {

        const checkedState = $(e.currentTarget).is(':checked');

        $checkbox.prop('checked', checkedState);

        checkAddDeleteButtons();
    });

    // check initial state of table row by adding class to <tr>
    app.DOM.table.find('tbody tr[id]').each((k, el) => {
        if ($(el).find('td.col_ctrl [data-modal="tco_mis_mil_comment_edit"]').length) {
            $(el).addClass('has-comment')
        } else {
            $(el).addClass('no-comment')
        }
    });

    // Refresh the list so that the bulk add button is included in the search
    app.DOM.btn_remodal = app.DOM.content.find('.modal-open');
    app.DOM.btn_remodal_bulk_add = app.DOM.btn_remodal.filter('.btn-comment-bulk-add');
    app.DOM.btn_remodal_bulk_delete = app.DOM.btn_remodal.filter('.btn-comment-bulk-delete');
}

export function TachoMissingMileage_bulk_comment(action, $modal) {
    let selector = 'td.col_multi > input:checked';

    if (action === 'add') {
        selector = '.no-comment td.col_multi > input:checked';
    } else if( action === 'delete' ) {
        selector = '.has-comment td.col_multi > input:checked';
    }

    const rowIDs = app.DOM.table.find(selector).map(
        (i, el) => el.value
    ).get();

    $modal.find('[name="gaps"]').val(rowIDs.join());

    TachoMissingMileage_load_information_into_modal_from_gap_id(rowIDs, $modal);
}

export function TachoMissingMileage_add_comment($btn, $modal) {
    const $row = $btn.parents('tr');
    const rowID = $row.attr('id').substr(12);
    $modal.find('[name="gaps"]').val(rowID);

    TachoMissingMileage_load_information_into_modal_from_gap_id([rowID], $modal);

    const start = DateTime.fromISO($btn.siblings('.mileage_start_date_iso').val());
    const end = DateTime.fromISO($btn.siblings('.mileage_end_date_iso').val());
    const tacho_vehicle_id = $btn.data('tacho-vehicle-id');
    TachoMissingMileage_attachActivityChart(tacho_vehicle_id, start, end, $modal)
    $modal.find('.view-activity')
      .removeClass('hidden')
      .click(TachoMissingMileage_configureActivityButton(tacho_vehicle_id, start, end))
}

export function TachoMissingMileage_edit_comment($btn, $modal) {
    const $tr = $btn.parents('tr')
    const id = $btn.siblings('input').val()
    const existing_comment = $tr.find('span.comment_text').text();

    $modal.find('#comment_id').val(id)
    $modal.find('#comment_edit').val(existing_comment)

    const $associatedDriver = $tr.find('.col_blame_driver a');
    const associatedDriverText = $associatedDriver.text();
    if(associatedDriverText.trim().length === 0) {
        return;
    }

    $modal.find('#tco_user_name').val(associatedDriverText);
    $modal.find('#tco_user_id').val($associatedDriver.data('id'));
    const commentData = $tr.find('.col_comment').text().trim().split('\n');
    const commentAuthor = $tr.find('.col_comment_author').text().trim();
    if(commentAuthor !== '-') {
        $modal.find('#comment-author').html(`<p><b>Comment Author:</b> ${commentAuthor}</p>`);
    }

    if(commentData[1] && commentData[1].trim()) {
        $modal.find('#comment-timestamp').html(`<p><b>Comment Date:</b> ${commentData[1].trim()}</p>`);
    }

    TachoMissingMileage_load_information_into_modal($tr, $modal);

    const start = DateTime.fromISO($btn.siblings('.mileage_start_date_iso').val());
    const end = DateTime.fromISO($btn.siblings('.mileage_end_date_iso').val());
    const tacho_vehicle_id = $btn.data('tacho-vehicle-id');
    TachoMissingMileage_attachActivityChart(tacho_vehicle_id, start, end, $modal)
    $modal.find('.view-activity')
      .removeClass('hidden')
      .off('click')
      .on('click', TachoMissingMileage_configureActivityButton(tacho_vehicle_id, start, end))
}

export function TachoMissingMileage_delete_comment($btn, $modal) {
    const $tr = $btn.closest('tr');
    const rowID = $tr.attr('id').substr(12)

    // update row id
    $modal.find('[name="gaps"]').val(rowID);

    TachoMissingMileage_load_information_into_modal($tr, $modal);
}

function TachoMissingMileage_load_information_into_modal($tableRows, $modal) {
    const html = $tableRows.map(
        function (id, element) {
            const $el = $(element);
            const reg = $el.find('.id_reg').text();
            const diff = $el.find('td.col_mileage_difference').text().trim();
            const start = $el.find('td.col_before_date').text().trim();
            const end = $el.find('td.col_after_date').text().trim();

            return `<li class="gap"><span class="id_reg uk id_reg_uk">${reg}</span> ${diff}km between ${start} and ${end}</li>`;
        }
    ).get().join('\n');

    $modal.find('ul.affected_gaps').html(html)
}

function TachoMissingMileage_load_information_into_modal_from_gap_id(gapIDs, $modal) {
    const rowIDs = gapIDs.map((g) => 'tco_mis_mil_' + g);
    const $rows = $('tr').filter((i, el) => rowIDs.includes(el.id));

    TachoMissingMileage_load_information_into_modal($rows, $modal)
}

export function TachoMissingMileage_store_new_comment($btn, $modal) {
    const targetURL = app.CACHE.URL_AJAX + 'tacho_missing_mileage_comment_add'
    TachoMissingMileage_save(targetURL, $modal, 'Comment has been added');
}

export function TachoMissingMileage_update_comment($btn, $modal) {
    const targetURL = app.CACHE.URL_AJAX + 'tacho_missing_mileage_comment_update'
    TachoMissingMileage_save(targetURL, $modal, 'Comment has been updated');
}

export function TachoMissingMileage_remove_comment($btn, $modal) {
    const targetURL = app.CACHE.URL_AJAX + 'tacho_missing_mileage_comment_delete';
    TachoMissingMileage_save(targetURL, $modal, 'Comment has been deleted');
}

function TachoMissingMileage_save(targetURL, $modal, title) {
    let data = {};
    $modal.find('input,textarea,select')
      .serializeArray()
      .forEach(o => {
          data[o.name] = o.value
      });

    AjaxSync({
        url: targetURL,
        data: data,
        method: 'POST',
    }, {
        done: (res) => {
            if ( res?.data?.action === 'reload' ) {
                window.location.reload();
            } else {
                app.DOM.table.find('tr#tco_mis_mil_' + data.gaps).addClass('fadeOut').fadeOut('slow').remove();
            }
        }
    });
}

export function TachoMissingMileage_bulk_none_paginated_comment($btn, modal)
{
    const targetURL = app.CACHE.URL_AJAX + 'tacho_missing_mileage_comment_bulk'
    TachoMissingMileage_save(targetURL, modal, `Comments added to ${app.OPTIONS.paging?.total} mileage gap(s)`);
}

export function TachoMissingMileage_attachActivityChart (tacho_vehicle_id, start, end, $modal) {
    // Add vehicle unit activity chart
    const buffer = { hours: 1 }
    const paramString = buildPipedUrlParametersFromNameValuePairs([
        { 'name': 'tacho_vehicle_id', 'value': tacho_vehicle_id },
        { 'name': 'start', 'value': start.minus(buffer).toISO() },
        { 'name': 'end', 'value': end.plus(buffer).toISO() },
    ])
    const $image = $('<object>').attr({
        type: 'image/svg+xml',
        data: `${app.CACHE.URL_ADMIN}img/vehicle_activity_view/${paramString}`
    })
    $modal.find('div#veh_activity').append($image)
}

function TachoMissingMileage_configureActivityButton(tacho_vehicle_id, start, end)
{
    return function (e) {
        e.preventDefault()

        const url = `${app.CACHE.URL_ADMIN}tco_veh_activity/${tacho_vehicle_id}/${start.toFormat('dd-MM-yyyy')}:${end.toFormat('dd-MM-yyyy')}`
        window.open(url, 'missing_mileage_veh_activity')
    }
}

$( () => {
    if(app.URI[2] === 'tco_mis_mil') {
        TachoMissingMileage_init();
    }

    window.TachoMissingMileage_bulk_comment = TachoMissingMileage_bulk_comment;
    window.TachoMissingMileage_bulk_none_paginated_comment = TachoMissingMileage_bulk_none_paginated_comment;
    window.TachoMissingMileage_add_comment = TachoMissingMileage_add_comment
    window.TachoMissingMileage_edit_comment = TachoMissingMileage_edit_comment;
    window.TachoMissingMileage_delete_comment = TachoMissingMileage_delete_comment;
    window.TachoMissingMileage_store_new_comment = TachoMissingMileage_store_new_comment;
    window.TachoMissingMileage_update_comment = TachoMissingMileage_update_comment;
    window.TachoMissingMileage_remove_comment = TachoMissingMileage_remove_comment;
});
